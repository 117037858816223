<template>
	<upload ref="upload"></upload>
</template>

<script>
import {
	showLoading,
	hideLoading
} from '../utils/Loading'
import {
	uploadFile,
	tutorSave,
	videoConvert
} from '../api/base.js'
import Client from '../utils/client.js'
import upload from './upload/index.vue'
import RecordRTC from 'recordrtc';
import { queryPlanById } from '../api/base'
export default {
	props:["paramsPlanId","planIdMicro"],
	
	data() {
		return {
			screenStream: null,
			fileName: new Date().getTime(),
			videoStart: false,// 开始答题
			recorder: null,
			options: {
				// 获取分片上传进度、断点和返回值。
				progress: (p, cpt, res) => { },
				// 设置并发上传的分片数量。
				parallel: 4,
				// 设置分片大小。默认值为1 MB，最小值为100 KB。
				partSize: 1024 * 1024,
			},
			isparamsPlanId:null,
			isplanIdMicro:null,
		}
	},

	components: {
		upload
	},
	watch: {
		videoStart(val) {
			this.$store.state.videoStart = val
		},
		paramsPlanId(o,n){
			this.paramsPlanId=o
			// this.isparamsPlanId=o
		},
		planIdMicro(o,n){
			this.planIdMicro=o
			// this.isplanIdMicro=o
		}
	},
	onLoad() {

	},
	mounted() {
console.log(this.paramsPlanId);
console.log(this.planIdMicro);
	},
	methods: {
		handleHttpRequest(videoFile, main) {
			showLoading()
			let that = this;
			try {
				if(this.$route.path == '/explainMicro'){
					var id = that.planIdMicro
				}else{
					var id = that.paramsPlanId
				}
				queryPlanById({
					id,
				}).then((res) => {
					console.log(res);
					let chapterObj = res.data.result.resourceRelationList.find(item=>item.relationKind=='chapter')
					let fileName = this.fileName + ".mp4";
					Client().multipartUpload(fileName, videoFile, {
						...this.options
					})
						.then((result) => {
							videoConvert({ url: "https://teachers-explanation.oss-cn-shanghai.aliyuncs.com/" + fileName }).then(res => {
								let data = {
									classId: JSON.parse(localStorage.getItem("teachclass_id")),
									fileUrl: res.data.data.url,
									mainId: main.mainId,
									mainKind: main.mainKind,
									mainName: main.mainName,
									// relationId:JSON.parse(localStorage.getItem('teachchaptersData')).id,
									// relationName:JSON.parse(localStorage.getItem('teachchaptersData')).name,
									// relationKind:'chapters',
									fromId: JSON.parse(localStorage.getItem('teachschoolID')),
									subjectId: JSON.parse(localStorage.getItem('teachsubjectId')),
									fileType: "https://teachers-explanation.oss-cn-shanghai.aliyuncs.com/" + fileName,
									// chapterId: JSON.parse(localStorage.getItem('teachchaptersData')).id
									chapterId:chapterObj.relationId
								}
								tutorSave(data).then(res => {
									this.$message({
										message: "结束录屏成功，请去微课查看",
										type: 'success',
										duration: 1500
									})
									hideLoading()
									if (this.$parent.parentVideoStart) {
										this.$parent.startRecording()
									}
								})
							})
						});
				})

			} catch (e) { }
		},
		//
		/**
		 * 开始录制
		 */
		startRecording(callback) {
			this.fileName = new Date().getTime()
			if (this.$store.state.screenStream) {
				this.screenStream = this.$store.state.screenStream
				this.addStreamStopListener(this.screenStream, () => {
					this.$emit("streamStop", {})
				});
				var options = {
					type: 'video',
					mimeType: 'video/mp4',
					disableLogs: false,
					getNativeBlob: false, // enable it for longer recordings
					ignoreMutedMedia: false
				};
				this.recorder = RecordRTC(this.screenStream, options);
				this.recorder.screen = this.screenStream;
				this.recorder.startRecording();
				this.videoStart = true;
				callback(true)
			} else {
				this.captureScreen((screenStream) => {
					this.addStreamStopListener(screenStream, () => {
						this.$emit("streamStop", {})
					});
					var options = {
						type: 'video',
						mimeType: 'video/mp4',
						disableLogs: false,
						getNativeBlob: false, // enable it for longer recordings
						ignoreMutedMedia: false
					};
					this.recorder = RecordRTC(screenStream, options);
					this.recorder.screen = screenStream;
					// 关闭录屏
					const videoTrack = screenStream.getVideoTracks()[0];
					videoTrack.addEventListener('ended', () => {
						this.screenStream = null
					})

					this.recorder.startRecording();
					this.videoStart = true;
					callback(true);
				});
			}
		},
		/**
		 * 停止录制
		 */
		stopRecording(callback) {
			if (!this.recorder) {
				return
			}
			this.recorder.stopRecording(() => {
				// this.video.src = this.video.srcObject = null;
				// this.video.src = URL.createObjectURL(this.recorder.getBlob());
				if (this.$store.state.dirHandle) {
					this.DownloadToLocal()
				} else {
					this.DownloadToLOss()
				}
				this.videoStart = false;
				callback(false);
			});
		},
		// 直接上传到oss
		DownloadToLOss() {
			let videoFile = new File([this.recorder.getBlob()], this.fileName + ".mp4", {
				type: 'video/mp4'
			})
			let downloadUrl = URL.createObjectURL(videoFile);
			let fd = new FormData();
			fd.append("file", videoFile);
			// uploadFile(fd).then(res => {})
			if (this.$route.path == '/explains') {
				let mainName = JSON.parse(localStorage.getItem("teachlupin")).name ? JSON.parse(localStorage.getItem("teachlupin")).name.questionOutVo ? JSON.parse(localStorage.getItem("teachlupin")).name.questionOutVo.featureTypeName : JSON.parse(localStorage.getItem("teachlupin")).name.name : null
				this.handleHttpRequest(videoFile, { mainName: mainName, mainId: JSON.parse(localStorage.getItem("teachlupin")).name.id, mainId: JSON.parse(localStorage.getItem("teachlupin")).name.id, mainKind: JSON.parse(localStorage.getItem("teachlupin")).stutas, })
			} else if (this.$route.path == '/explainMicro') {
				this.handleHttpRequest(videoFile, { mainName: JSON.parse(localStorage.getItem("teachNote")).name, mainId: JSON.parse(localStorage.getItem("teachNote")).id, mainKind: "planId" })
			} else {
				this.handleHttpRequest(videoFile, { mainName: JSON.parse(localStorage.getItem("teach_val")).questionOutVo.featureTypeName, mainId: JSON.parse(localStorage.getItem("teach_val")).id, mainKind: "question" })
			}
		},
		// 下载到本地
		DownloadToLocal() {
			let urlName;
			if (this.$route.path.indexOf('explains') != -1) {
				let mainName = JSON.parse(localStorage.getItem("teachlupin")).name ? JSON.parse(localStorage.getItem("teachlupin")).name.questionOutVo ? JSON.parse(localStorage.getItem("teachlupin")).name.questionOutVo.featureTypeName : JSON.parse(localStorage.getItem("teachlupin")).name.name : null
				urlName = this.DownloadName({ mainName: mainName, mainId: JSON.parse(localStorage.getItem("teachlupin")).name.id, mainKind: JSON.parse(localStorage.getItem("teachlupin")).stutas })
			} else if (this.$route.path.indexOf('explainMicro') != -1) {
				urlName = this.DownloadName({ mainName: JSON.parse(localStorage.getItem("teachNote")).name, mainId: JSON.parse(localStorage.getItem("teachNote")).id, mainKind: "planId" })
			} else {
				urlName = this.DownloadName({ mainName: JSON.parse(localStorage.getItem("teach_val")).questionOutVo.featureTypeName, mainId: JSON.parse(localStorage.getItem("teach_val")).id, mainKind: "question" })
			}
			const url = URL.createObjectURL(this.recorder.getBlob());
			const a = document.createElement("a");
			document.body.appendChild(a);
			a.style.display = "none";
			a.href = url;
			a.download = "xingzhengjin" + urlName + '.mp4'
			a.click();
			setTimeout(() => {
				if (this.$refs.upload) {
					this.$refs.upload.init()
				}
				if (this.$parent.parentVideoStart) {
					this.$parent.startRecording()
				}
			}, 100);
			this.$parent.videoStart = false;
		},
		//下载命名
		DownloadName(data) {
			// fileUrl:  res.data.data.url,
			// fileType:"https://teachers-explanation.oss-cn-shanghai.aliyuncs.com/" + fileName,
			// 1669631356132xingzhengjin_仿写句子_15803_question_422_1000147_21_117153_
			let name = '_' + data.mainName + '_' + data.mainId + '_' + data.mainKind + '_' + JSON.parse(localStorage.getItem("teachclass_id")) + '_' + JSON.parse(localStorage.getItem('teachschoolID')) + '_' + JSON.parse(localStorage.getItem('teachsubjectId')) + '_' + JSON.parse(localStorage.getItem('teachchaptersData')).id + '_'
			return name
		},
		//初始化
		captureScreen(callback) {
			let that = this
			if (navigator.getDisplayMedia) {
				//录制结束,文件下载
				navigator.getDisplayMedia({
					video: true
				}).then(screenStream => {
					navigator.mediaDevices.getUserMedia({
						audio: true
					}).then((mic) => {
						screenStream.addTrack(mic.getTracks()[0]);
						callback(screenStream);
					});
				}).catch(function (error) { });
			} else if (navigator.mediaDevices.getDisplayMedia) {
				that.$parent.videoStart = false
				that.$parent.screenRecordingShow = false
				navigator.mediaDevices.getDisplayMedia({
					video: true
				}).then(screenStream => {
					that.$parent.videoStart = true
					that.$parent.screenRecordingShow = true
					navigator.mediaDevices.getUserMedia({
						audio: true
					}).then((mic) => {
						screenStream.addTrack(mic.getTracks()[0]);
						callback(screenStream);
					}).catch(function (error) {
						that.$store.commit('getScreenStream', screenStream)
						that.screenStream = screenStream
						callback(screenStream);
					});
				}).catch(function (error) {
				});
			} else {
				var error = 'getDisplayMedia API are not supported in this browser.';
				alert(error);
			}
		},

		//流监听
		addStreamStopListener(stream, callback) {
			stream.addEventListener('ended', function () {
				callback();
				callback = function () { };
			}, false);
			stream.addEventListener('inactive', function () {
				callback();
				callback = function () { };
			}, false);
			stream.getTracks().forEach(function (track) {
				track.addEventListener('ended', function () {
					callback();
					callback = function () { };
				}, false);
				track.addEventListener('inactive', function () {
					callback();
					callback = function () { };
				}, false);
			});
		},

	}
}
</script>

<style></style>
